// deno-lint-ignore-file no-namespace

namespace RequestMethod {
    export const GET = 'GET' as const;
    export type GET = typeof GET;

    export const HEAD = 'HEAD' as const;
    export type HEAD = typeof HEAD;

    export const POST = 'POST' as const;
    export type POST = typeof POST;

    export const PUT = 'PUT' as const;
    export type PUT = typeof PUT;

    export const DELETE = 'DELETE' as const;
    export type DELETE = typeof DELETE;

    export const CONNECT = 'CONNECT' as const;
    export type CONNECT = typeof CONNECT;

    export const OPTIONS = 'OPTIONS' as const;
    export type OPTIONS = typeof OPTIONS;

    export const TRACE = 'TRACE' as const;
    export type TRACE = typeof TRACE;

    export const PATCH = 'PATCH' as const;
    export type PATCH = typeof PATCH;
}

type RequestMethod = typeof RequestMethod[keyof typeof RequestMethod];

export default RequestMethod;
